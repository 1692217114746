import {useEffect, useState} from "react";

function Post() {

    const [posts, setPosts] = useState([]);
    console.log(posts, 'posts')
    useEffect(() => {
        async function loadPosts() {
            const response = await fetch('https://catfact.ninja/fact');
            if(!response.ok) {
                // oups! something went wrong
                return;
            }

            const posts = await response.json();
            setPosts(posts);
        }

        loadPosts();
    }, [])


    return (
        <div className="App">
            <h1 className="text-3xl font-bold underline">
               Hello Friend
            </h1>


        </div>
    );
}

export default Post;
