
import {useEffect, useState} from "react";
import { Link } from "react-router-dom";

interface IpostData{
    title: {
        rendered: string;
    };
    content: {
        rendered: string;
    }
}

function Home() {

    const [posts, setPosts] = useState([]);
    console.log(posts, 'posts')
    useEffect(() => {
        async function loadPosts() {
            const response = await fetch('http://devio.site/wp-json/wp/v2/posts');
            if(!response.ok) {
                // oups! something went wrong
                return;
            }

            const posts = await response.json();
            setPosts(posts);
        }

        loadPosts();
    }, [])


    return (
        <div className="App">
            <ul>
                <li> <Link to={'about'}>About us</Link></li>
            </ul>
            <h1 className="text-3xl font-bold underline">
                Hello world!
            </h1>
            {posts.map((post: IpostData, index) => {
                return <div>
                    <p dangerouslySetInnerHTML={{__html: post.title.rendered}}></p>
                    <p dangerouslySetInnerHTML={{__html: post.content.rendered}}></p>
                </div>
            })}
        </div>
    );
}

export default Home;
