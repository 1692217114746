import {NavLink, Outlet} from "react-router-dom";

const RootLayout = () => {
    return (
        <div>
            <nav>
                <NavLink to='/'>Home</NavLink>
                <NavLink to='/about'>About</NavLink>
            </nav>
            <Outlet />
        </div>
    )
}

export default RootLayout
