import {BrowserRouter, Routes, Route, Outlet} from "react-router-dom";
// pages
import Home from './pages/Home'
import About from './pages/About'


// layouts
import RootLayout from "./layout/rootLayout";



function App() {
  return (
      <BrowserRouter basename="/">
          <Routes>
              <Route
                  element={<RootLayout />}
              >
                  <Route path='/' element={<Home />} />
                  <Route path="about" element={<About />} />
              </Route>
          </Routes>
      </BrowserRouter>
  );
}

export default App
